<template>
  <div style="background-color: #FFF; min-height: 100vh">
    <div class="text-xs-center" v-if="loading_inicial">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading_inicial">
       <v-container fluid grid-list-md fill-height>
        <v-layout row wrap align-center justify-center fill-height>
          <!-- Web -->
          <v-flex xs12 sm12 md6 class="hidden-sm-and-down align-center justify-center" style="text-align: center; background: #efefef; padding: 3%; min-height: 100vh;">
            <!-- Right WEB -->
            <cpnHeaderWeb class="hidden-sm-and-down"/>
            <v-flex xs12 sm12 md12 align-center>
              <div class="header text--center hidden-sm-and-down pt-5">
                <v-layout align-center justify-center row wrap fill-height>
                  <!-- <span
                    class="display-1 px-5 py-1 font-weight-bold teal white--text hidden-sm-and-down"
                  >Unidade Consumidora!</span> -->
                  <div class="headline teal--text pb-4 pt-4 hidden-sm-and-down">
                    Informe o numero da sua instação.
                  </div>
                  <p class="grey--text text--darken-4">Conforme está na sua conta de luz</p>
                  <img
                    src="@/assets/img/unidade-consumidora.png"
                    width="100%"
                    style="padding: 5px"
                    alt="Exemplo de Conta Cemig"
                    class="src"
                  />
                </v-layout>
              </div>
            </v-flex>
          </v-flex>
          <!-- Mobile -->
          <v-flex xs12 sm12 md6 class="hidden-md-and-up" style="text-align: center; background: #fff; padding: 3%;">
            <!-- Header Mobile -->
            <cpnHeaderMobile class="hidden-md-and-up" :hasDuvidas="false"/>
          </v-flex>
          <v-flex d-flex xs12 sm12 md6 class="pt-3" style="text-align: center;">
            <div class="form_conta" style="padding-top: 0px">
              <div class style="padding: 0">
                <!-- Mobile -->
                <cpnSteps class="hidden-md-and-up pt-2" :steps="{steps: 5, e1: 2}"/>
                <v-flex xs12 sm12 md12>
                  <div class="hidden-md-and-up pt-5">
                    <div class="headline teal--text pb-4 pt-4">
                      Informe o numero da sua instação.
                    </div>
                    <p>Conforme está na sua conta de luz</p>
                    <img
                      src="@/assets/img/unidade-consumidora.png"
                      width="95%"
                      style="padding: 5px"
                      alt="Exemplo de Conta Cemig"
                      class="src"
                    />
                  </div>
                </v-flex>
                <!-- Fim Mobile -->
                <!-- WEB -->
                <cpnSteps  class="hidden-sm-and-down pt-2 pb-3" :steps="{steps: 5, e1: 2}"/>
                <div
                    class="display-1 hidden-sm-and-down"
                    style="padding-bottom: 15px; color: #808080"
                  >UNIDADE CONSUMIDORA</div>
                <!-- Fim Web -->
                <br />
                <v-form ref="form" lazy-validation class="form_class">
                  <!-- Numero de Instalacao -->
                  <v-flex xs12>
                    <v-text-field
                      v-model="installation_number"
                      label="Numero de Instalação"
                      name="installation_number"
                      :rules="installation_numberRules"
                      required
                    ></v-text-field>
                  </v-flex>
                  <!-- Titular ou familiar -->
                  <header style="text-align: left">Você é o titular da conta de luz?</header>
                  <v-radio-group
                    v-model="titular"
                    :rules="[
                      v => !!v || 'Por favor, informe sobre o titular da conta!'
                    ]"
                  >
                    <v-radio :key="1" :label="`Sou titular da Conta`" value="Sim"></v-radio>
                    <v-radio :key="2" :label="`Sou familiar do titular`" value="Familiar"></v-radio>
                  </v-radio-group>
                  <div style="color: red; text-align: left">{{ alertMessage }}</div>
                  <div class="my-2">
                    <v-btn
                      :loading="loading"
                      block
                      large
                      style="background-color: teal; font-weight: bold"
                      @click.prevent="validate()"
                    >Continuar</v-btn>
                  </div>
                </v-form>
                <br />
              </div>
              <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex"
import { saveAnalytics } from "@/services/save_analytics"
import { SET_CONVERSION } from "../../graphql/rdstation"

// import { setRDConversion } from "@/services/rdstation";
import moment from "moment"
import HeaderWeb from "../../components/web/Header"
import HeaderMobile from "../../components/mobile/Header"
import Steps from '../../components/core/Steps'
import { STORE_LEAD, GET_LEAD } from "../../graphql/leads"

export default {
  components: {
    cpnHeaderWeb: HeaderWeb,
    cpnHeaderMobile: HeaderMobile,
    cpnSteps: Steps
  },
  data() {
    return {
      loading_inicial: true,
      loading: false,
      installation_number: "",
      titular: "",
      installation_numberRules: [value => !!value || "Por favor, informe o número de instalação da sua unidade consumidora!"],
      alertMessage: "",
      isProd: false,
      lead: {}
    }
  },
  created() {
    this.isProd = process.env.NODE_ENV === "production"
    if(this.$route.query.email) {
      const email = this.$route.query.email
      // console.log('email: ', email)
      this.getLead(email)
        .then(lead => {
          this.setLead(lead.data.lead)
          this.loading = false
          this.loading_inicio = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading = false
          this.loading_inicio = false
        })
    } else {
      const lead = JSON.parse(localStorage.getItem("lead"))
      // console.log('lead: ', lead)
      if(lead && lead.email) {
        this.setLead(lead)
      }
      this.loading = false
      this.loading_inicio = false
    }
  },
  methods: {
    async getLead(email) {
      return await this.$apollo.query({
        query: GET_LEAD,
        variables: {
          email: email
        }
      })
    },
    setLead(lead) {
      this.lead = lead
      this.installation_number = lead.installation_number
      this.titular = lead.titular
    },
    formatDate(value) {
      if (!value) return ""
      const birthday = new Date(value.replace(/-/g, "/").replace(/T.+/, ""))
      return moment(birthday).format("DD/MM/YYYY")
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.loading = true
        const new_lead = {
          ...this.lead,
          installation_number: this.installation_number,
          titular: this.titular,
        }
        this.$store.commit("storeUserState", new_lead)

        this.storeLead(new_lead)
          .then((result) => {
            if (result.data) {
              // store buyer_id, consumer_id, ... and the rest like email, name
              localStorage.setItem("lead", JSON.stringify(new_lead))
              if (this.isProd) {
                // analytics
                const analytics = {
                  eventCategory: "onboard",
                  eventAction: "CREATE-INSTALLATION_NUMBER",
                  eventLabel: "CREATE-INSTALLATION_NUMBER",
                  value: new_lead.average_consumption
                }
                saveAnalytics(analytics)
              }
              this.processing = false
              // this.$router.push("cadastro-boas-vindas")
              this.$router.push("/cadastrar/assinatura")
            }
          })
          .catch(error => {
            console.error("Erro ao atualizar lead: ", error)
            return false
          })
      }
    },

    storeLead(lead) {
      // const group = "customer"
      return this.$apollo.mutate({
        // Query
        mutation: STORE_LEAD,
        // Parameters
        variables: {
          id: lead.email,
          email: lead.email,
          name: lead.name,
          installation_number: this.installation_number,
          titular: this.titular,
          step: 4
        }
      })
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    }
  },
  mounted() {
    this.loading_inicial = false
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>
